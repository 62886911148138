import './App.css';
import casinos from './resources/casinos.module';
import Navbar from './components/Navbar/Navbar.component';
import Footer from './components/Footer/Footer.component';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home/Home.component';
import Redirect from './components/Redirect/Redirect.component';
import BonusList from './components/BonusList/BonusList.component';

function App() {
  return (
    
    <div>
      <Routes>
        
      </Routes>
      <Navbar />
      <main className="Main">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/bonuslist" element={<BonusList />} />
            {
              casinos.map(casino => 
                <Route
                  exact path={casino.internalUri}
                  element={<Redirect link={casino.affiliateLink} />}
                  key = {casino.name}
                />  
              )
            }
          </Routes>
          
      </main>
      <Footer />
    </div>
  );
}

export default App;
