import data from '../../resources/casinos.module'
import styles from './BonusList.module.scss'

export default function BonusList(){
    return (
        <>
            <section className={styles.BonusContainer}>
                {
                    data.map(casino => 
                        <article className={styles.BonusRow}>
                            <img src={"/casinos/" + casino.filename } />
                            {casino.name}
                        </article>    
                    )
                }
            </section>
        </>
    )
}