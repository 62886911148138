const data = [
    {
        name: "Mr. Green",
        order: 1,
        filename: "mrgreen128.png",
        internalUri: "/mrgreen",
        affiliateLink: "https://ads.mrgreen.com/redirect.aspx?pid=4782965&bid=21225&redirectURL=https://mrgreen.com/",
        bonus: [
            {
                title: "Bonus (players from Finland)",
                items: [
                    "100% up to 500€",
                    "+ 100 free spins",
                ]
            },
            {
                title: "Bonus",
                items: [
                    "100% up to 200€",
                    "+ 100 free spins",
                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x bonus",
                    "Max bet: 5€",
                ]
            },
        ],
        tags: "mr green 100% up to 500€ + 100 free spins",
        recommended: true
    },
    {
        name: "Lucky Spins",
        order: 1,
        filename: "luckyspins128.png",
        internalUri: "/luckyspins",
        affiliateLink: "https://media.rhinoaffiliates.com/redirect.aspx?pid=6492&bid=1884",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100% up to 200€",
                    "",

                ]
            },
            {
                title: "Wager",
                items: [
                    "35x",
                    "Max bet: 5€",
                ]
            },
        ],
        tags: "lucky spins 100% up to 200€ 35x Max bet: 5€",
        recommended: true
    },
    {
        name: "Posido",
        order: 1,
        filename: "posidon128.png",
        internalUri: "/posido",
        affiliateLink: "https://ps.lp247p.com/C.ashx?btag=a_7343b_1434c_&affid=1795&siteid=7343&adid=1434&c=",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100% up to 500€",
                    "",

                ]
            },
            {
                title: "Wager",
                items: [
                    "30 x deposit + bonus",
                    "Max bet: 5€",
                ]
            },
        ],
        tags: "posidon 100% up to 500€ 30 x deposit + bonus Max bet: 5€",
        recommended: true
    },
    {
        name: "Lightcasino",
        order: 1,
        filename: "lightcasino128.png",
        internalUri: "/lightcasino",
        affiliateLink: "https://ltc-bc-7s.lptrak.com/redirect.aspx?pid=1533159&bid=8708",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "450 % up to 800€",
                    "+ 250 free spins",

                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x deposit + bonus",
                    "Max bet: 5€",
                    "Minimum deposit: 10€"
                ]
            },
        ],
        tags: "lightcasino 450 % up to 800€ + 250 free spins 35 x deposit + bonus Max bet: 5€ Minimum deposit: 10€",
        recommended: true
    },
    {
        name: "Spinz",
        order: 1,
        filename: "spinz260.png",
        internalUri: "/spinz",
        affiliateLink: "https://go.rootzaffiliates.com/visit/?bta=901564&nci=5816",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 300€",
                    "+ 100 free spins",

                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x",
                    "Max bet: 5€"
                ]
            },
            {
                title: "Free spins 10 per day over 10 days",
                items: []
            },
        ],
        tags: "spinz, 100 % deposit bonus up to 300€, + 100 free spins, Max bet: 5€, Free spins 10 per day over 10 days",
        recommended: true
    },
    {
        name: "Cobracasino",
        order: 1,
        filename: "cobracasino128.png",
        internalUri: "/cobracasino",
        affiliateLink: "https://media.highaffiliates.com/redirect.aspx?pid=4346&bid=1566",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 500€",
                    "+ 250 free spins",

                ]
            },
            {
                title: "Wager",
                items: [
                    "40 x bonus"
                ]
            },
            {
                title: "You will also get 2 more deposit bonuses",
                items: []
            },
        ],
        tags: "cobracasino 100 % deposit bonus up to 500€ + 250 free spins 40 x bonus You will also get 2 more deposit bonuses",
        recommended: true
    },
    {
        name: "Zulabet",
        order: 1,
        filename: "zulabet128.png",
        internalUri: "/zulabet",
        affiliateLink: "https://affiliates-portal.7starspartners.com/signup.html?a=659537",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100% deposit bonus up to 500€",
                    "+ 200 free spins",

                ]
            },
            {
                title: "Wager",
                items: [
                    "35x deposit + bonus"
                ]
            },
            {
                title: "Sportsbook bonus",
                items: []
            },
        ],
        tags: "zulabet 100% deposit bonus up to 500€ + 200 free spins 35x deposit + bonus Sportsbook bonus",
        recommended: true
    },
    {
        name: "Cazimbo",
        order: 1,
        filename: "cazimbo128.png",
        internalUri: "/cazimbo",
        affiliateLink: "https://go.affilirise.com/redirect.aspx?pid=2519&bid=1514",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 1000€",
                    "+ 150 free spins",
                ]
            },
            {
                title: "Sportsbook bonus",
                items: [
                    "100 % up to 200€"
                ]
            },
        ],
        tags: "cazimbo 100 % deposit bonus up to 1000€ + 150 free spins Sportsbook bonus 100 % up to 200€",
        recommended: true
    },
    // {
    //     name: "Unibet",
    //     order: 1,
    //     filename: "unibet128.png",
    //     internalUri: "/unibet",
    //     affiliateLink: "https://www.unibet.com/registration?mktid=1:0:3321-1&btag=0_E3569274B22C498FBF1D539D475F1648&bid=1&campaignId=1046037&pid=3321",
    //     bonus: [
    //         {
    //             title: "Bonus",
    //             items: [
    //                 "100 % deposit bonus up to 100€"
    //             ]
    //         },
    //         {
    //             title: "Wager",
    //             items: [
    //                 "25 x bonus. Max bet: 5€"
    //             ]
    //         },
    //     ],
    //     tags: "unibet 100 % deposit bonus up to 100€ 25 x bonus. Max bet: 5€",
    //     recommended: true
    // },
    {
        name: "Gambola",
        order: 1,
        filename: "gambola128.png",
        internalUri: "/gambola",
        affiliateLink: "https://record.glitnoraffiliates.com/_RWlPqZNqFeZDp78Ph1_jCGNd7ZgqdRLk/1",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "Deposit 10-25€ and if you don’t double up, you’ll get your money back in 48h",
                ]
            },
        ],
        tags: "gambola Deposit 10-25€ and if you don’t double up, you’ll get your money back in 48h",
        recommended: true
    },
    {
        name: "Scatters",
        order: 1,
        filename: "scatters128.png",
        internalUri: "/scatters",
        affiliateLink: "https://record.scatters.com/v2/text/29/1/82589fe8-e321-11ea-857f-9eefd9120cdc/1",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "25€ Risk Free Deposit, Double Up or Get it Back",
                ]
            },
        ],
        tags: "scatters 25€ Risk Free Deposit, Double Up or Get it Back",
        recommended: true
    },
    {
        name: "LuckyCasino",
        order: 1,
        filename: "luckycasino128.png",
        internalUri: "/luckycasino",
        affiliateLink: "https://record.glitnoraffiliates.com/_RWlPqZNqFeYdHrf4TDP9mWNd7ZgqdRLk/1",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "Deposit 10-25€ and if you don’t double up, you’ll get your money back in 48h",
                ]
            },
        ],
        tags: "luckycasino Deposit 10-25€ and if you don’t double up, you’ll get your money back in 48h",
        recommended: true
    },
    {
        name: "StickyWilds",
        order: 1,
        filename: "stickywilds128.png",
        internalUri: "/stickywilds",
        affiliateLink: "https://aff-ads.stickywilds.com/text/befa1721000088000003000001000001000001000001",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 500€",
                    "+ 200 free spins",
                ]
            },
        ],
        tags: "stickywilds 100 % deposit bonus up to 500€ + 200 free spins",
        recommended: true
    },
    {
        name: "LuckyDino",
        order: 1,
        filename: "luckydino128.png",
        internalUri: "/luckydino",
        affiliateLink: "https://affmore.com/click/0/34931030E24711EAADB665A60EE36010",
        bonus: [
            {
                title: "1. deposit bonus 100 % up to 200€",
                items: [
                    "+ 200 free spins",
                ]
            },
            {
                title: "2. deposit bonus 50 % up to 200€",
                items: [
                    "+ 50 free spins"
                ]
            },
        ],
        tags: "luckydino 1. deposit bonus 100 % up to 200€ + 200 free spins 2. deposit bonus 50 % up to 200€ + 50 free spins",
        recommended: true
    },
    {
        name: "Casumo",
        order: 1,
        filename: "casumo128.png",
        internalUri: "/casumo",
        affiliateLink: "https://ads.casumoaffiliates.com/redirect.aspx?pid=1212350&bid=1546",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100% deposit bonus up to 2000€",
                    "+ 20 free spins",

                ]
            },
        ],
        tags: "casumo 100% deposit bonus up to 2000€ + 20 free spins",
        recommended: true
    },
    {
        name: "Dunder",
        order: 1,
        filename: "dunder128.png",
        internalUri: "/dunder",
        affiliateLink: "https://media.dunderaffiliates.com/redirect.aspx?pid=743516&bid=2121",
        bonus: [
            {
                title: "Sign up bonus",
                items: [
                    "20 free spins (no deposit needed)",
                    "+ 200 free spins",

                ]
            },
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 100€",
                    "+ 100 free spins"
                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x deposit + bonus. Max bet: 5€"
                ]
            },
        ],
        tags: "dunder, 20 free spins (no deposit needed), + 200 free spins, 100 % deposit bonus up to 100€, + 100 free spins, 35 x deposit + bonus. Max bet: 5€",
        recommended: true
    },
    {
        name: "Wildz",
        order: 1,
        filename: "wildz128.png",
        internalUri: "/wildz",
        affiliateLink: "https://go.rootzaffiliates.com/visit/?bta=901564&nci=5930",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 500€",
                    "+ 200 free spins"
                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x bonus",
                    "Max bet: 5€"
                ]
            },
        ],
        tags: "wildz 100 % deposit bonus up to 500€ + 200 free spins 35 x bonus Max bet: 5€",
        recommended: true
    },
    {
        name: "Caxino",
        order: 1,
        filename: "caxino128.png",
        internalUri: "/caxino",
        affiliateLink: "https://www.caxino.com/fi/?aff=cx-901564_596055",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 200€",
                    "+ 100 fre spins"
                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x bonus",
                    "Max bet: 5€"
                ]
            },
        ],
        tags: "caxino 100 % deposit bonus up to 200€ + 100 fre spins 35 x bonus Max bet: 5€",
        recommended: true
    },
    {
        name: "Wheelz",
        order: 1,
        filename: "wheelz256.png",
        internalUri: "/wheelz",
        affiliateLink: "https://go.rootzaffiliates.com/visit/?bta=901564&nci=5344",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 300€",
                    "+ 100 free spins"
                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x bonus",
                    "Max bet: 5€"
                ]
            },
        ],
        tags: "wheelz 100 % deposit bonus up to 300€ + 100 free spins 35 x bonus Max bet: 5€",
        recommended: true
    },
    {
        name: "Wild Blaster",
        order: 1,
        filename: "wildblaster128.png",
        internalUri: "/wildblaster",
        affiliateLink: "https://vip-wildblaster.tech/a0c5257cf",
        bonus: [
            {
                title: "Bonus",
                items: [
                    "100 % deposit bonus up to 100€",
                    "+ 100 free spins",
                ]
            },
            {
                title: "Bonus",
                items: [
                    "75% up to 50€",
                    "25 % up to 200€"
                ]
            },
            {
                title: "Wager",
                items: [
                    "60 x bonus Max bet: 5€"
                ]
            },
        ],
        tags: "wild blaster 100 % deposit bonus up to 100€ + 100 free spins 75% up to 50€ 25 % up to 200€ 60 x bonus Max bet: 5€",
        recommended: true
    },
    {
        name: "Cadoola",
        order: 1,
        filename: "cadoola128.png",
        internalUri: "/cadoola",
        affiliateLink: "https://cdl-bc-7s.lptrak.com/redirect.aspx?pid=1533159&bid=8723",
        bonus: [
            {
                title: "NON-STICKY BONUSES!",
                items: [
                    "1. bonus: 120 % deposit bonus up to 240€ + 100 freespins",
                    "2. bonus: 110 % deposit bonus up to 110€",
                    "3. bonus: 100 % deposit bonus up to 100€  + 150 free spins",
                    "4. bonus: 120 % deposit bonus up to 240€"

                ]
            },
            {
                title: "Wager",
                items: [
                    "35 x deposit + bonus",
                    "Max bet: 5€, Minimum deposit: 10€",
                ]
            },
        ],
        tags: "posidon 100% up to 500€ 30 x deposit + bonus Max bet: 5€",
        recommended: true
    },
]

export default data;