import { useEffect } from "react";
import styles from './Redirect.module.scss'

export default function Redirect({link}){
    useEffect(() => {
        window.location.href = link;
    }, []);
    
    return (
        <section className={styles.RedirectContainer}>
            <span>Redirecting to </span>
            <a href={link}>{link}</a>
        </section>
    );
}