import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './Home.module.scss';
import data from '../../resources/casinos.module';

export default function Home(){

    const [searchValue, setSearchValue] = useState(null);
    const [filteredList, setFilteredList] = useState(data);

    useEffect( () => {
        if(searchValue != null){
            let newList = data.filter(
                casino => (
                    casino.tags.toLowerCase().includes(searchValue)
                )
            )
            setFilteredList(newList);
        } else {
            setFilteredList(data);
        }
    }, [searchValue])

    const inputEventSearch = (e) => {
        let value = e.target.value;
        if(value != "" && value.length > 2){
            setSearchValue(value.toLowerCase());
        } else {
            setSearchValue(null);
        }
    }

    return (
        <>
            <section className={styles.bonusHeader}>
                <h1>CasinoQuestTV's recommended bonuses</h1>
            </section>
            <section className={styles.bonusSearch}>
                <input
                    type="text"
                    placeholder="Search for recommended bonus"
                    onKeyUp={inputEventSearch}
                />
            </section>
            
            <section className={styles.bonusContainer}>
                {filteredList.map(casino => 
                    <a
                        className={styles.alink}
                        target="_blank"
                        href={casino.internalUri}
                        key = {casino.name}
                    >
                        <article
                            className={styles.bonusCard}
                            key={casino.name}
                        >
                            <header className={styles.bonusCardImage}>
                                <img src={"/casinos/" + casino.filename } />
                            </header>
                            <section className={styles.bonusCardDescription}>
                                <h2>{casino.name}</h2>
                                <ul className={styles.bonusList}>
                                {casino.bonus.map(bonus =>
                                    <li
                                        key={bonus.title}
                                    >
                                        <h3>{bonus.title}</h3>
                                        <ul className={styles.bonusItemList}>
                                            {bonus.items.map((item, index) => 
                                                <li key={index}>{item}</li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                                </ul>
                            </section>
                        </article>
                    </a>
                )}
            </section>
        </>
    )
}