import styles from './Footer.module.scss';

export default function Footer(){
    return (
        <footer className={styles.Footer}>
            <section className={styles.FooterLeftSection}>
                <h4 className={styles.FooterHeader}>Follow us</h4>
                <a href="https://www.instagram.com/casinoquest.tv/" target="_blank">
                  <img className={styles.followIcon} src="/icon-ig.png" alt="instagram.com/casinoquest.tv" />
                </a>
                <a href="https://www.twitch.tv/casinoquesttv" target="_blank">
                  <img className={styles.followIcon} src="/icon-twitch.png" alt="twitch.tv/casinoquesttv" />
                </a>
            </section>
            <section className={styles.FooterMiddleSection}>
                <h4 className={styles.FooterHeader}>Contact us</h4>
                <a className={styles.FooterEmail} href="mailto:office@casinoquest.tv">office@casinoquest.tv</a>
            </section>
            <section className={styles.FooterRightSection}>
                <h4 className={styles.FooterHeader}>Useful links</h4>
                <ul className={styles.FooterLinkList}>
                    <li>
                        <a href="https://www.begambleaware.org/">BeGambleAware</a>
                    </li>
                </ul>
            </section>
        </footer>
    )
}