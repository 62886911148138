import React from 'react';
import styles from './Navbar.module.scss';
import logo from './logo.png';
import { NavLink, Link } from 'react-router-dom';

export default function Navbar(){
    return (
        <header className={styles.Navbar}>
            <nav className={styles.NavbarMenuContainer}>
                <ul>
                    <li>
                        <a className={styles.NavLink} target="_blank" href="https://twitch.tv/casinoquesttv">Twitch</a>
                    </li>
                </ul>
            </nav>
            <div className={styles.NavbarImageContainer}>
                <NavLink to="/">
                    <img src={logo} />
                </NavLink>
            </div>
            <div className={styles.NavbarRightContainer}>

            </div>
        </header>
    )
}